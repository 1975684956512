<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components'
import { Ref, onBeforeUnmount, onMounted, ref } from 'vue'

import { OutlineXMark } from '@ankor-io/icons/outline'

import { useModal } from '@/modal/useModal'

const { showCloseButton, canCloseOnBackdrop, updateModalState } = useModal()

/**
 * Reference on MutationObserver and usage:
 * https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
 * To close the modal open state when the dom is emptied
 */

// Select the node that will be observed for mutations
const modalContainer: Ref<HTMLElement | null> = ref(null)

// Callback function to execute when mutations are observed
const callback = (mutationList: MutationRecord[]) => {
  if (!mutationList) {
    return
  }

  const element = mutationList[0].target as HTMLElement
  if (element.id !== 'modal-container') {
    return
  }

  // This is the only added line outside the docs to fit the requirement of modal staying open after navigating back
  if (!element.hasChildNodes()) {
    updateModalState(false)
  }
}

// Create an observer instance linked to the callback function
const observer = new MutationObserver(callback)

onMounted(() => {
  modalContainer.value = document.getElementById('modal')

  // Options for the observer (which mutations to observe)
  const config = { attributes: true, childList: true, subtree: true }

  // Start observing the target node for configured mutations
  observer.observe(modalContainer.value!, config)
})

onBeforeUnmount(() => {
  // Later, you can stop observing
  observer.disconnect()
})
</script>
<template>
  <div
    tabindex="1"
    aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto inset-0 h-full backdrop-blur-[2px] p-2"
    @keydown.esc="canCloseOnBackdrop && updateModalState(false)"
  >
    <div class="relative shrink-0 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-fit">
      <!-- Modal content -->
      <OnClickOutside @trigger="canCloseOnBackdrop && updateModalState(false)">
        <div
          id="modal"
          class="relative bg-white border border-gray-200 dark:bg-gray-800 dark:border-gray-600 rounded-lg shadow-md p-8"
        >
          <div v-if="showCloseButton" class="absolute right-8 top-8 flex justify-end">
            <OutlineXMark
              class="cursor-pointer w-3 h-3 self-center stroke-gray-900 hover:stroke-primary-600 dark:stroke-gray-400 dark:hover:stroke-primary-600"
              @click="updateModalState(false)"
            />
          </div>
          <slot></slot>
        </div>
      </OnClickOutside>
    </div>
  </div>
</template>
