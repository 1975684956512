import { App } from 'vue'
import algoliasearch, { AlgoliaSearchOptions } from 'algoliasearch/lite'
import InstantSearch from 'vue-instantsearch/vue3/es'
import { AppSearchClient } from './types'

/**
 * Create a search client
 *
 * @param bearerToken use this token to create the user based api token
 * @param appId the Algolia app id
 * @param options the Algolia client options
 * @returns a Search client
 */
export async function createSearchClient(
  bearerToken: string | undefined,
  appId: string,
  options?: AlgoliaSearchOptions,
): Promise<AppSearchClient> {
  //
  // Create a search key; see:
  //  https://www.algolia.com/doc/guides/security/api-keys/how-to/user-restricted-access-to-data/
  const buildSearchKey = async (bearerToken: string | undefined): Promise<string> => {
    const data = await fetch('/search/', {
      method: 'GET',
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      redirect: 'error', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    })
      //
      .then((response) => {
        if (!response.ok) {
          throw new Error('error creating search key')
        }
        // response is:
        // { 'key': 'value' }
        return response.json()
      })
      //
      .catch((error) => {
        console.error('Error fetching search key', error)
        return error
      })
    //
    const entry = data as { key: string }
    return entry.key
  }

  // build the search key.
  const key = await buildSearchKey(bearerToken)
  // create the search client.
  const client = algoliasearch(appId, key, options)
  // create a function to allow the client to be installed to the app.
  const appSearchClient = client as AppSearchClient
  appSearchClient.install = (app: App) => {
    // the search client needs InstantSearch
    app.use(InstantSearch)
    app.config.globalProperties.$searchClient = client
  }
  //
  return appSearchClient
}
