import { Plugin } from 'vue'
import { SentryConfig } from '@ankor-io/sentry-conflux/src/types'
import { AuthenticationClientOptions } from '@/iam/types'

/**
 * The config type definition
 */

export declare type Config = {
  /**
   * The configured websocket url
   */
  WS_URL: string

  /**
   * The Stowage http endpoint url
   */
  STOWAGE_URL: string

  /**
   * Kinde client related configurations
   */
  AUTHENTICATION_CLIENT_OPTIONS: AuthenticationClientOptions

  /**
   * Algolia config setting relevant to the front end. Important: do not include any api keys here.
   */
  ALGOLIA: {
    /**
     * The algolia app id
     */
    app_id: string
    /**
     * The algolia place index
     */
    place_index: string
    /**
     * The algolia geo key
     */
    geo_key: string
    /**
     * The algolia search key
     */
    search_key: string
  }
  /**
   * GROWTHBOOK config for creating a growthbook instance
   * Use the SDK connection since the endpoint has now been deprecated
   */
  GROWTHBOOK: {
    /**
     * Client key used for registration of SDK connection
     */
    clientKey: string
    /**
     * Whether to enable development mode
     */
    enableDevMode: boolean
  }
  /**
   * Sentry tracing settings
   */
  SENTRY: SentryConfig
  /**
   * The tripshub configurations
   * @deprecated this config entry is deprecated in favour of the hubspot apps config
   */
  TRIPSHUB: {
    /**
     * The tripshub app client id
     */
    clientId: string
    /**
     * The tripshub app client secret
     */
    redirectUri: string
  }
  /**
   * The hubspot apps configurations
   */
  HUBSPOT_APPS: {
    /**
     * The hubspot app name
     */
    name: string
    /**
     * The hubspot app description
     */
    description: string
    /**
     * The hubspot app install url
     */
    installUrl: string
  }[]
}

// intersect our main type with a Vue app plugin for using it in the app.
export type AppConfig = Config & Plugin
export const ConfigKey: string = 'config'
